// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowModel, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {
    Clone,
    CustomFormField, CustomFormFieldOption,
    ICompany,
    IProject,
    ISurvey, ISurveyAnswer,
    IUser, SurveyAnswerObject, SurveyObject, SurveyObjectQuestion, SurveyWarningType
} from "../../types/interfaces";
import {ISurveyFilter, ISurveyWithId, SurveyDataController} from "./SurveyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {
    Box,
    Button, List, ListItem, ListItemIcon, ListItemText,
    Modal,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";

import {EditObject} from "../../Shared/Components/EditObject";
import {
    CustomFormFieldSizes,
    CustomFormFieldTypes,
    DEFAULT_PAGE_SIZE_OPTIONS,
    UserRole
} from "../../types/enums";
import {Link as RouterLink} from "react-router-dom";
import {UserDataController} from "../Users/UserDataController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faCircleCheck,
    faFileImport,
    faPlus,
    faRotateRight, faShield,
    faTrash, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {ImportSurvey} from "./Management/ImportSurvey";
import moment from "moment";
import {createContentWithClone, RenderCellExceptionCount, showInReport} from './Management/RenderCellExceptionCount';
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {MultiActionButtonAction, RenderCellMultiButton} from "./Management/RenderCellMultiButton";
import {CloneSurveyButton} from "./Management/CloneSurveyButton";
import {RenderCellGenerateSurveyReport} from "./Management/RenderCellGenerateSurveyReport";
import {RenderCellViewAnswer} from "./Management/RenderCellViewAnswer";
import {AddNewSurveyModal} from "./Management/AddNewSurveyModal";
import useWebSocket from "react-use-websocket";
import {ReportDataController, SurveyAnswerDataController} from "../Reports/ReportDataController";
import {CheckSurveyObjectQuestionForWarnings} from "../../utils/warningSystem";

// https://mui.com/material-ui/icons/#font-awesome

// , onCancel: (() => void)

/*

Filter options that have been removed for server side pagination and filtering
- Date filter
- Show only unassigned date filter
- Search User, and Project Name

 */

const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
export enum WebSocketServerAction {
    NOOP = "NOOP",
    ERROR = "ERROR",
    NewReportGenerated = "NewReportGenerated"
}

export interface WebSocketServerMessage {
    action: WebSocketServerAction;
    data: any | null;
}

// NOTE: this will be used later, don't remove!
// enum WebSocketClientAction {
//     NOOP = "NOOP",
//     ERROR = "ERROR"
// }
//
// export interface WebSocketClientMessage {
//     action: WebSocketClientAction;
//     data: any | null;
// }


export interface DateFilter {
    filterStart_year: number | null;
    filterStart_month: number | null;
    filterStart_day: number | null;
    filterEnd_year: number | null;
    filterEnd_month: number | null;
    filterEnd_day: number | null;
}
export interface StoredPaginationModel {
    pageSize: number;
    page: number;
}

export function RenderCellExceptionCountCell(props: { userToken: string, params: GridCellParams}) {
    // const row = props.params.row as SurveyObjectQuestion;

    const exceptionMemo = React.useMemo(() => {
        try {
            let survey = props.params.row as ISurvey;
            let answers = survey.answers;
            if (answers === undefined || answers === null || answers.length === 0) {
                return null;
            }
            let answer = answers[0];
            if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {

                return null;
            }

            // let count = 0;

            let contentWithClones = createContentWithClone(survey.content, answer, answer.survey_id, true);

            // TODO: get list of objects that either oare non-conditional or have passed the conditional check
            let contentWithConditionalObjects = contentWithClones.filter((object) => {

                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                let objectAnswers = answer.answers[object.key];
                if (objectAnswers === undefined) {
                    return false; // does objects that are conditional but have no answer show up
                }

                return objectAnswers.conditionalExists === true;
            });

            // let answeredObjects = await asyncMap<SurveyObject, { tableRows: TableRow[], object: SurveyObject }>(contentWithConditionalObjects, GetSurveyObjectToTableRowsFunction(reportType, item))
            // console.log("answeredObjects", answeredObjects);

            // console.log("contentWithConditionalObjects", contentWithConditionalObjects);

            let results: {question: string}[] = [];

            contentWithConditionalObjects.forEach((obj) => {

                if (answer === undefined || answer === null || answer.answers === undefined || answer.answers === null) {
                    return;
                }
                // console.log(obj)
                let objectAnswers = answer.answers[obj.key];
                if (objectAnswers === undefined || objectAnswers === null || objectAnswers.values === undefined || objectAnswers.values === null) {
                    return;
                }
                // console.log(objectAnswers)
                let questions = obj.questions;
                if (questions === undefined || questions === null || questions.length === 0) {
                    return;
                }

                questions.forEach((question) => {
                    let questionAnswers = objectAnswers.values[question.key];
                    if (questionAnswers === undefined) {
                        return;
                    }
                    // console.log("question", question)
                    if (question.showInExceptionCount === undefined || question.showInExceptionCount === null){
                        return;
                    }

                    // console.log(questionAnswers)
                    if (showInReport(question.showInExceptionCount, objectAnswers)) {
                        // console.log("showInReport", question.showInExceptionCount)
                        // count++;
                        results.push({
                            question: question.questionText,
                            // answer: objectAnswers.values
                        })
                    }
                    // if (questionAnswers.exception !== undefined && questionAnswers.exception !== null && questionAnswers.exception !== "") {
                    //     count++;
                    // }
                })
            });

            return results;
        }
        catch (e) {
            console.log(e);
            return null;
        }

    }, [props.params.row ]);




    const warningsTitleMemo = React.useMemo(() => {
        if (exceptionMemo === null || exceptionMemo.length === 0) {
            // return "No Warnings";
            return (<>
                <div style={{ maxHeight: '250px', overflowY: "auto"}}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{
                                        color: 'green',
                                        fontSize: '2rem'
                                    }}
                                />
                            </ListItemIcon>

                            <ListItemText primary={"No Exceptions"} />
                        </ListItem>
                    </List>
                </div>
            </>)
        }

        return (<>
            <div style={{ maxHeight: '250px', overflowY: "scroll"}}>
                <List>
                    {exceptionMemo.map((warning) => {
                        return (<>
                            <ListItem>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        // icon={warning.type === SurveyWarningType.warning ? faShield : faTriangleExclamation}
                                        style={{
                                            // color: warning.type === SurveyWarningType.warning ? 'orange' : 'red',
                                            color: 'orange',
                                            fontSize: '2rem'
                                        }}
                                    />
                                </ListItemIcon>

                                <ListItemText primary={warning.question} />
                            </ListItem>
                        </>)
                    })}
                </List>
            </div>
        </>)
    }, [exceptionMemo])

    const iconMemo = React.useMemo(() => {
        if (exceptionMemo === null || exceptionMemo.length === 0) {
            return {icon: faCircleCheck, color: 'green'};
        }
        // if (exceptionMemo.find((item) => item.type === SurveyWarningType.error) !== undefined) {
        //     return {icon:faTriangleExclamation, color: 'red'};
        // }
        return {icon:faTriangleExclamation, color: 'orange'};
    }, [exceptionMemo])

    return (<>
        <Tooltip title={warningsTitleMemo} >
            {exceptionMemo !== null ? (<>
                <Stack direction="row">
                    <FontAwesomeIcon
                        icon={iconMemo.icon}
                        style={{
                            color: iconMemo.color,
                            fontSize: '2em'
                        }}
                    />

                    <Typography>&nbsp;{exceptionMemo.length}</Typography>
                </Stack>
            </>): <></>}
        </Tooltip>
    </>)
}


function SurveyManagementViewPaginated() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [usersList, setUserList] = React.useState<IUser[]>([])


    const [list, setList] = React.useState<ISurveyWithId[]>([]);
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("SurveyManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];
    const [showOnlyUnassignedDateFilter, setShowOnlyUnassignedDateFilter] = useLocalStorage("SurveyManagementView.showOnlyUnassignedDateFilter", false) as [boolean, React.Dispatch<React.SetStateAction<boolean>>];


    const [paginationModel, setPaginationModel] = useLocalStorage("SurveyManagementView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];

    // TODO: data filters aren't being saved in local storage
    const [dateFilter, setDateFilter] = useLocalStorage("SurveyManagementView.dateFilter", {
        filterStart_year: null,
        filterStart_month: null,
        filterStart_day: null,
        filterEnd_year: null,
        filterEnd_month: null,
        filterEnd_day: null,
    }) as [DateFilter, React.Dispatch<React.SetStateAction<DateFilter>>];

    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let filter:  ISurveyFilter = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            searchText: searchText,
            isTemplate: false,
            isArchived: false,
            ...dateFilter
        }
        let response = await new SurveyDataController(userToken).getPaginated(filter);
        setList(response.items);
        setTotalRows(response.count);
    },[userToken, paginationModel.page, paginationModel.pageSize, searchText, dateFilter]);

    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, dateFilter, UpdateList]);


    const GenerateActionButtonGroup1 = React.useCallback((params: GridCellParams) => {
        let actions: MultiActionButtonAction[] = [
            {
                label: "Archive",
                action: () => {
                    async function archiveRow() {
                        let row = params.row as ISurvey & { _id: string };
                        row.isArchived = true;

                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        await new SurveyDataController(userToken).update(row._id, row);
                        await UpdateList()
                    }

                    if (window.confirm("Are you sure you want to archive this survey?")){
                        archiveRow().then();
                    }
                }
            },
            {
                label: "Export",
                action: () => {
                    async function download(){
                        if (userToken === null || userToken === undefined) {
                            return;
                        }

                        let fileContent = await new SurveyDataController(userToken).downloadExport(params.row._id);
                        // window.open(url, '_blank');
                        console.log(fileContent)

                        // Create a Blob from the string data
                        const blob = new Blob([fileContent], { type: 'application/json' });

                        // Create a Blob URL
                        const blobUrl = window.URL.createObjectURL(blob);

                        // Create a download link
                        const downloadLink = document.createElement('a');
                        downloadLink.href = blobUrl;
                        downloadLink.download = params.row._id + ".json"; // Specify the file name here
                        // downloadLink.style.display = 'none';

                        // Trigger the download
                        // document.body.appendChild(downloadLink);
                        downloadLink.click();

                    }

                    download().then();
                }
            },
            {
                label: "Create Answer",
                action: () => {
                    // alert("Not implemented yet")
                    // return;

                    async function createAnswer() {
                        let row = params.row as ISurvey & { _id: string };
                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        let emptyAnswer = {
                            survey_id: row._id,
                            answers: {
                                "______": {
                                    "values": {
                                        "_______": {
                                            "text": {
                                                "data": "Never used"
                                            }
                                        }
                                    }
                                }
                            } as SurveyAnswerObject,
                            clones: {} as { [key: string]: Clone[] },
                            isCompleted: false,
                        } as ISurveyAnswer;
                        await new SurveyAnswerDataController(userToken).create(row._id, emptyAnswer);
                        await UpdateList()
                    }

                    if (window.confirm("Are you sure you want to create answer to this survey?")){
                        createAnswer().then();
                    }
                }
            },
        ];

        return actions;
    }, [UpdateList, userToken]);

    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER || user?.role === UserRole.USER_MANAGER) {
            columns.push({
                field: 'open_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (
                    <Tooltip title={"Open Survey"} arrow={true}>
                        <Button variant="outlined" color="primary" fullWidth={true} component={RouterLink}
                                to={`/dashboard/surveys/${params.id}`}>
                            Open
                        </Button>
                    </Tooltip>


                )
            });

            columns.push({
                field: 'clone_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (<>

                        <CloneSurveyButton
                            userToken={userToken}
                            setList={setList}
                            survey={params.row as ISurveyWithId}
                            updateList={() => {
                                UpdateList().then();
                            }}
                            usersList={usersList}
                        />
                    </>
                ),
            });
        }

        if (user?.role !== UserRole.TECHNICIAN) {
            columns.push(
                {
                    field: 'generate_btn',
                    headerName: '',
                    sortable: false,
                    width: 120,
                    renderCell: (params: GridCellParams) => (
                        <RenderCellGenerateSurveyReport params={params} updateListRequested={() => {
                            // TODO: not using this anymore.  Remove it. Can't remove since it is used for delete also.
                            UpdateList().then();
                        }}/>
                    ),
                }
            )
        }

        columns.push(
            {
                field: 'view_btn',
                headerName: '',
                sortable: false,
                width: 150,
                renderCell: (params: GridCellParams) => (
                    <RenderCellViewAnswer params={params} />
                ),
            }
        )

        columns.push({ field: 'caseNumber', headerName: 'Case Number', width: 300, resizable: true, editable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title={params.value}>
                        <Typography sx={{ padding: "3px", width: '100%' }}>{params.value}</Typography>
                    </Tooltip>
                </>
            ),
        });
        columns.push({ field: 'status', headerName: 'Status', width: 100, editable: false });
        // columns.push({ field: 'isArchived', headerName: 'isArchived', width: 100, editable: false });

        columns.push({ field: 'surveyDate', headerName: 'Survey Date', width: 200, editable: false,
            valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;

                    if (
                        survey.surveyDate_year !== undefined && survey.surveyDate_year !== null &&
                        survey.surveyDate_month !== undefined && survey.surveyDate_month !== null &&
                        survey.surveyDate_day !== undefined && survey.surveyDate_day !== null &&
                        survey.surveyDate_hour !== undefined && survey.surveyDate_hour !== null &&
                        survey.surveyDate_minute !== undefined && survey.surveyDate_minute !== null
                    ) {
                        let surveyDate = new Date(survey.surveyDate_year, survey.surveyDate_month - 1, survey.surveyDate_day, survey.surveyDate_hour, survey.surveyDate_minute);
                        return surveyDate.toLocaleString();
                        // details.push({ label: "Survey Date", value: surveyDate.toLocaleDateString() });
                    }
                    else if (survey.surveyDate !== undefined && survey.surveyDate !== null){
                        // return new Date(survey.surveyDate).toLocaleString();
                        return survey.surveyDate.toLocaleString();
                    }
                    else {
                        return ""
                    }
                }
                catch (e: any) {
                    // alert(e.message)
                    return ""
                }
            }
        });

        // columns.push({ field: 'exception_reports', headerName: 'Exception Count', width: 120, editable: false,
        //     renderCell: (params) => (
        //         <RenderCellExceptionCount params={params} />
        //     ),
        // });

        if (userToken !== null && userToken !== undefined) {
            columns.push({field: 'valid', headerName: 'Exception Count', width: 120, renderCell: (params: GridCellParams) => (<><RenderCellExceptionCountCell userToken={userToken} params={params}/></>) })
        }
        if (user?.role !== UserRole.CLIENT) {

            columns.push({ field: 'company_project', headerName: 'Company / Project', width: 300, editable: false,
                valueGetter: (params) => {
                    let survey = params.row as ISurvey;
                    if (survey !== undefined && survey.project !== null && survey.project !== undefined) {
                        let project = survey.project as IProject;
                        if (project !== undefined && project.company !== null && project.company !== undefined) {
                            return (project.company as ICompany).name + " / " + project.name
                        }
                        // return (survey.project as IProject).company.name;
                    }
                    return "";
                }
            });

        columns.push({ field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.assigned_user_id !== undefined){
                        let user = survey.assigned_user_id as IUser;
                        return user.name;
                    }
                    else {
                        return "Unassigned"
                    }
                }
                catch (e) {
                    return "Unassigned"
                }

            } });
        columns.push({ field: 'coordinator_user_id', headerName: 'Coordinator', width: 300, editable: false, valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.coordinator_user_id !== undefined){
                        let user = survey.coordinator_user_id as IUser;
                        return user.name;
                    }
                    else {
                        return "Unassigned"
                    }
                }
                catch (e) {
                    return "Unassigned"
                }

            } });


            columns.push({field: 'surveyOwner', headerName: 'Survey Owner', width: 300, editable: false});
            columns.push({
                field: 'createdAt', headerName: 'Created At', width: 200, editable: false,
                valueFormatter: params => {
                    try {
                        if (params.value === undefined || params.value === null) {
                            return "";
                        }
                        let date = new Date(params.value as string);
                        return date.toLocaleString();
                    } catch (e) {
                        // console.log(e);
                        return "";
                    }
                }
            });
        }



        if (user?.role === UserRole.CLIENT) {
            columns.push({field: 'locationName', headerName: 'Location Name', width: 300, editable: false});
            columns.push({field: 'address', headerName: 'Site Address', width: 300, editable: false});
        }

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER || user?.role === UserRole.USER_MANAGER) {


            columns.push({
                field: 'action_1_btn',
                headerName: '',
                sortable: false,
                width: 250,
                renderCell: (params: GridCellParams) => (
                    <RenderCellMultiButton params={params} getActions={GenerateActionButtonGroup1} />
                ),
            });
        }


        if (user?.role === UserRole.ADMIN) {

            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let item = params.row as ISurvey & { _id: string };
                                try {
                                    await navigator.clipboard.writeText(item._id);
                                    console.log('Text copied to clipboard');
                                } catch (err) {
                                    console.error('Failed to copy: ', err);
                                }
                            }}>Copy</Button>

                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                        </Stack>
                    </>
                ),
            });
        }
        else if (user?.role !== UserRole.CLIENT) {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }

        return columns;
    }, [user, userToken, UpdateList, usersList, GenerateActionButtonGroup1])

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    const [archiveActive, setArchiveActive] = React.useState<boolean>(false);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        try {
            let usersList = await new UserDataController(userToken).getAll();
            if (usersList === null || usersList === undefined) {
                alert("Error loading");
                return;
            }
            setUserList(usersList.filter((u) => u.role !== UserRole.CLIENT).sort((a, b) => a.name.localeCompare(b.name)));
            // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
        } catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [userToken]);

    React.useEffect(() => {
        loadData().then();
    }, [userToken, loadData]);

    const [showAddNewCodeModal, setShowAddNewCodeModal] = React.useState<boolean>(false);
    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }
            return await new SurveyDataController(userToken).update(newRow._id, newRow as ISurveyWithId);
        },
        [userToken],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
    }, []);

    const [showDateFilter, setShowDateFilter] = React.useState<boolean>(false);
    const [showImportModal, setShowImportModal] = React.useState<boolean>(false);

    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [
            {
                label: "Date Filter",
                action: () => {
                    setShowDateFilter(!showDateFilter);
                },
                icon: <FontAwesomeIcon icon={faCalendar} />,
                color: dateFilter.filterStart_year !== null || dateFilter.filterStart_month !== null || dateFilter.filterStart_day !== null || dateFilter.filterEnd_year !== null || dateFilter.filterEnd_month !== null || dateFilter.filterEnd_day !== null ? "warning" : "primary"
            },
        ];


        if (user?.role !== UserRole.CLIENT) {
            buttons.push({
                label: "Create",
                action: () => {
                    setShowAddNewCodeModal(true);
                },
                icon: <FontAwesomeIcon icon={faPlus} />
            });
            buttons.push({
                label: "Import",
                action: () => {
                    setShowImportModal(true);
                },
                icon: <FontAwesomeIcon icon={faFileImport} />
            });
            buttons.push();


        // Add button

            buttons.push({
                icon: <FontAwesomeIcon icon={faTrash} />,
                label: "Archive",
                action: () => {

                    async  function archiveSurveys() {
                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        for (let i = 0; i < selectionModel.length; i++) {
                            let id = selectionModel[i];
                            let survey = list.find((s) => s._id === id);
                            if (survey === undefined || survey === null || survey._id === undefined || survey._id === null) {
                                continue;
                            }
                            survey.isArchived = true;
                            await new SurveyDataController(userToken).update(survey._id, survey);
                        }
                        UpdateList().then();
                    }

                    setArchiveActive(true);
                    if (window.confirm(`Are you sure you want to archive ${selectionModel.length} surveys?`)) {
                        archiveSurveys().then(() => {
                            setArchiveActive(false);
                        });
                    }
                },
                disabled: selectionModel.length === 0 || archiveActive,
            });


            buttons.push({
                label: "Reload",
                action: () => {
                    loadData().then();
                },
                icon: <FontAwesomeIcon icon={faRotateRight} />
            })
        }

        return buttons;
    }, [archiveActive, selectionModel, userToken, list, dateFilter, loadData, showDateFilter, user?.role]);

    const yearOptions = React.useMemo(() => {
        let options = [
            {label: "Un-filtered", value: null}
        ];

        // TODO: show 1 year previous and 5 years in the future
        for (let i = 0; i <= 5; i++) {
            let adjustedYear = i + new Date().getFullYear();
            let option: CustomFormFieldOption = {label: adjustedYear.toString(), value: adjustedYear};
            options.push(option);
        }
        return options;
    }, []);

    const dayOptions = React.useMemo(() => {
        let options = [
            {label: "Un-filtered", value: null}
        ];

        // let maxDays = new Date(dateObject.year, dateObject.month, 0).getDate();
        let maxDays = 31;
        for (let i = 1; i <= maxDays; i++) {
            let option: CustomFormFieldOption = {label: i.toString(), value: i};
            options.push(option);
            // options.push({label: i, value: i} as CustomFormFieldOption);
        }
        return options;
    }, []);

    const monthOptions: CustomFormFieldOption[] = React.useMemo(() => {
        return [
            {label: "Un-filtered", value: null},
            {label: "January", value: 1},
            {label: "February", value: 2},
            {label: "March", value: 3},
            {label: "April", value: 4},
            {label: "May", value: 5},
            {label: "June", value: 6},
            {label: "July", value: 7},
            {label: "August", value: 8},
            {label: "September", value: 9},
            {label: "October", value: 10},
            {label: "November", value: 11},
            {label: "December", value: 12},
        ]
    }, []);


    const DateFilterForm = React.useMemo(() => {
        return [
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterStart_year", label: "Start Year", options: yearOptions},
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterStart_month", label: "Start Month", options: monthOptions},
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterStart_day", label: "Start Day", options: dayOptions},
            {
                kind: CustomFormFieldTypes.PASSTHRU,
                size: CustomFormFieldSizes.ONE,
                key: "clearStartButton",
                label: "Clear",
                passthru: ButtonPassThru,
                passthruProps: { onClick: () => {
                    setDateFilter({
                        filterStart_year: null,
                        filterStart_month: null,
                        filterStart_day: null,
                        filterEnd_year: dateFilter.filterEnd_year,
                        filterEnd_month: dateFilter.filterEnd_month,
                        filterEnd_day: dateFilter.filterEnd_day,
                    });
                } }
            },
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterEnd_year", label: "End Year", options: yearOptions},
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterEnd_month", label: "End Month", options: monthOptions},
            {kind: CustomFormFieldTypes.SELECT, size: CustomFormFieldSizes.ONE, key: "filterEnd_day", label: "End Day", options: dayOptions},

            {
                kind: CustomFormFieldTypes.PASSTHRU,
                size: CustomFormFieldSizes.ONE,
                key: "clearEndButton",
                label: "Clear",
                passthru: ButtonPassThru,
                passthruProps: { onClick: () => {
                    setDateFilter({
                        filterStart_year: dateFilter.filterStart_year,
                        filterStart_month: dateFilter.filterStart_month,
                        filterStart_day: dateFilter.filterStart_day,
                        filterEnd_year: null,
                        filterEnd_month: null,
                        filterEnd_day: null
                    });
                } }
            },

            {
                kind: CustomFormFieldTypes.PASSTHRU,
                size: CustomFormFieldSizes.FULL,
                key: "setToToday",
                label: "Today",
                passthru: ButtonPassThru,
                passthruProps: { onClick: () => {
                        let today = moment(); // This is a Moment.js date object representing now
                        setDateFilter({
                            filterStart_year: today.year(),
                            filterStart_month: today.month() + 1,
                            filterStart_day: today.date(),
                            filterEnd_year: today.year(),
                            filterEnd_month: today.month() + 1,
                            filterEnd_day: today.date()
                        });
                    } }
            },
        ];
    }, [dateFilter, yearOptions, monthOptions, dayOptions, setDateFilter]);


    const processMessages = React.useCallback((event: WebSocketEventMap['message']) => {
        console.log("Received message: ", event.data);
        let data = JSON.parse(event.data) as WebSocketServerMessage;
        console.log("Received message: ", data);
        switch (data.action) {

            case WebSocketServerAction.NOOP:
                break;
            case WebSocketServerAction.ERROR:
                console.error("Error: ", data);
                alert(data.data)
                break;
            case WebSocketServerAction.NewReportGenerated:
                // alert("New report generated")
                UpdateList().then();
                // TODO: do this more directly with the row id
                break;
        }
    }, [UpdateList])

    // sendJsonMessage
    useWebSocket(WS_URL + '/?token=' + encodeURIComponent(userToken ?? ""), {
        onOpen: () => console.log('WebSocket connection opened.'),
        onClose: () => console.log('WebSocket connection closed.'),
        shouldReconnect: (closeEvent) => true,
        onMessage: (event: WebSocketEventMap['message']) =>  processMessages(event)
    });

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Surveys"}
                searchText={{text: searchText, setText: setSearchText, label: user?.role === UserRole.CLIENT ? "Search (Case Number)": "Search (Case Number)"}}
                                  // , Assigned User, Project Name
                customButtons={customButtonsMemo}
            >

                <DataGrid
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelection}
                    checkboxSelection={user?.role === UserRole.ADMIN}
                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />

                <Modal
                    open={showAddNewCodeModal}
                    onClose={() => { setShowAddNewCodeModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <AddNewSurveyModal
                            usersList={usersList}
                            onSave={async (survey: ISurvey) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new SurveyDataController(userToken).create(survey);
                                await UpdateList()
                                setShowAddNewCodeModal(false);
                            }}
                            onCancel={() => { setShowAddNewCodeModal(false) }}
                        ></AddNewSurveyModal>
                    </>}
                </Modal>

                <Modal
                    open={showImportModal}
                    onClose={() => { setShowImportModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <ImportSurvey
                            userToken={userToken}
                            usersList={usersList}
                            onSave={async (survey: ISurvey) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new SurveyDataController(userToken).create(survey);
                                await UpdateList();
                                setShowImportModal(false);
                            }}

                            onCancel={() => { setShowImportModal(false) }}
                        />
                    </>}
                </Modal>

                <Modal
                    open={showDateFilter}
                    onClose={() => { setShowDateFilter(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 700,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4
                        }}>
                            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                                Date Filter
                            </Typography>
                
                            <Stack direction={"column"} spacing={2}>

                                <EditObject item={dateFilter} setItem={setDateFilter} form={DateFilterForm} columns={4}></EditObject>
                
                                {user?.role !== UserRole.CLIENT && (<>
                                    <div style={{ padding: '0.5rem' }}>
                                        <Switch
                                            disabled={true}
                                            checked={showOnlyUnassignedDateFilter}
                                            onChange={(e) => {
                                                setShowOnlyUnassignedDateFilter(e.target.checked);
                                            }}
                                        />
                                        <label>Show Only Unassigned Date [Coming Soon]</label>
                                    </div>
                                </>)}
                            </Stack>
                        </Box>
                
                </Modal>


            </AuthenticatedLayout>
        </div>
    );
}





export const ButtonPassThru = (props : { onClick: () => void, field: CustomFormField }) => {
    return (<>
        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
            props.onClick()
        }}>{props.field.label}</Button>
    </>);
}

export default SurveyManagementViewPaginated;
