import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {
    CustomFormField, IQuickQuestion,
} from "../../types/interfaces";
import {GridCellParams,  GridValidRowModel} from "@mui/x-data-grid";
import {CustomFormFieldSizes, CustomFormFieldTypes, DEFAULT_PAGE_SIZE_OPTIONS} from "../../types/enums";

import {useLocalStorage} from "../../Hooks/useLocalStorage";
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload, } from "@fortawesome/free-solid-svg-icons";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import {DownloadOutlined as DownloadOutlineIcon} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {BankDataController_QuickQuestion} from "./BankDataController_QuickQuestion";
import {QuickSelectOptions} from "../Surveys/SurveyEditor/QuickSelectOptions";
import EditIcon from "@mui/icons-material/Edit";
import {EditObject} from "../../Shared/Components/EditObject";
import {StoredPaginationModel} from "../Surveys/SurveyManagementView";

function QuickQuestionView() {

    const {
        userToken
    } = React.useContext(AuthContext)!;

    const [paginationModel, setPaginationModel] = useLocalStorage("QuickQuestionView.paginationModel", {
        pageSize: 25,
        page: 0,
    }) as [StoredPaginationModel, React.Dispatch<React.SetStateAction<StoredPaginationModel>>];
    const [totalRows, setTotalRows] = React.useState<number>(0);

    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new BankDataController_QuickQuestion(userToken).getPaginated(paginationModel);
        setQuickQuestionsList(response.items);
        setTotalRows(response.count)
    }, [userToken, paginationModel])

    React.useEffect(() => {
        UpdateList().then()
    }, [userToken, paginationModel, UpdateList]);

    const [quickQuestionsList, setQuickQuestionsList] = React.useState<IQuickQuestion[]>([]);


    const columns = [
        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'content.key', headerName: 'Key', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.key;
            }},
        { field: 'content.questionText', headerName: 'Question Text', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.questionText;
            }},
        { field: 'content.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.type.type;
            }},

        { field: 'edit_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<EditBankQuestion question={params.row as IQuickQuestion} saveQuestion={async (question) => {
                    // TODO: Implement

                    if (userToken === null || userToken === undefined) {
                        return false;
                    }
                    let controller = new BankDataController_QuickQuestion(userToken);
                    await controller.update(question._id, question);
                    let all = await controller.getAll();
                    setQuickQuestionsList(all);
                    return true;

                    // return false;

                }} />)
            }},

        { field: 'export_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {
                    let quickObject: IQuickQuestion = params.row as IQuickQuestion;
                    let fileContent = JSON.stringify(quickObject, null, 4);
                    // Create a Blob from the string data
                    const blob = new Blob([fileContent], { type: 'application/json' });

                    // Create a Blob URL
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = params.row._id + ".json_questions"; // Specify the file name here

                    downloadLink.click();
                }}><DownloadOutlineIcon /></IconButton>)
            }},

        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"error"} onClick={() => {
                    if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                        let item = params.row as IQuickQuestion & { _id: string };
                        if (userToken === null || userToken === undefined) {
                            alert("You must be logged in to export.");
                            return;
                        }
                        new BankDataController_QuickQuestion(userToken).delete(item._id).then(async (response) => {
                            await UpdateList();
                            // let all = await new BankDataController_QuickQuestion(userToken).getAll();
                            // setQuickQuestionsList(all);
                        });
                    }
                }}><DeleteIcon /></IconButton>)
            }},
    ]




    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [

        ];

        if (userToken !== null && userToken !== undefined) {

            buttons.push({
                label: "Import",
                action: () => {
                    // setShowAddNewCodeModal(true);

                    // make sure you update the key or remove id from it so it doesn't collide with others.
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.json_questions';
                    input.onchange = async (e) => {
                        const file = (e.target as HTMLInputElement).files![0];
                        const reader = new FileReader();
                        reader.onload = async (e) => {
                            const text = (e.target as FileReader).result;
                            console.log(`file content: ${text}`);

                            let parsed = JSON.parse(text as string) as IQuickQuestion;
                            console.log(`parsed: ${JSON.stringify(parsed)}`);
                            if (parsed) {
                                console.log(`parsed: ${JSON.stringify(parsed.content)}`);

                                if (parsed.content.questionText.length > 0) {
                                    // console.log(`parsed: ${JSON.stringify(parsed.content.questions[0])}`);
                                    let controller = new BankDataController_QuickQuestion(userToken);
                                    let response = await controller.create(parsed);
                                    console.log(`response: ${JSON.stringify(response)}`);

                                    alert("Imported successfully.")
                                    return
                                }
                            }
                            alert("Failed to parse file.");
                        }
                        reader.readAsText(file);
                    }
                    input.click();
                },
                icon: <FontAwesomeIcon icon={faFileUpload}/>
            });
        }

        return buttons;
    }, [userToken]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={`Question Bank`}
                                  customButtons={customButtonsMemo}
            >

                <DataGridPro
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: 'calc( 100% - 5rem )' }}
                    rows={quickQuestionsList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />

            </AuthenticatedLayout>
        </div>
    );
}

export const EditBankQuestion = ({ question, saveQuestion } : { question: IQuickQuestion, saveQuestion: (question: IQuickQuestion) => Promise<boolean> }) => {

    const [open, setOpen] = React.useState(false);

    // TODO: convert the type to something usable?


    const [item, setItem] = React.useState<any>({...question.content, ...question.content.type});

    const formFieldsMemo = React.useMemo(() => {
        let customFormFields: CustomFormField[] = [];

        customFormFields.push({
            key: "key",
            kind: CustomFormFieldTypes.TEXT,
            label: "Key",
            size: CustomFormFieldSizes.FULL,
            readonly: true
        })

        customFormFields.push({
            key: "questionText",
            kind: CustomFormFieldTypes.TEXT,
            label: "Question Text",
            size: CustomFormFieldSizes.FULL,
            // readonly: true
        })

        customFormFields.push({
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "type",
            label: "Type",
            options: [
                {label: "Text", value: "text"},
                {label: "Toggle", value: "toggle"},
                {label: "Select", value: "select"},
                {label: "Photo", value: "photo"},
                {label: "Geo Coordinate", value: "geoCoordinate"},
                {label: 'Document', value: 'document'},
                // {label: 'Signature', value: 'signature'},
                {label: 'Multi Select', value: 'multiSelect'},
                {label: 'Instruction', value: 'instruction'},
            ],
            // readonly: true
        })

        if (item.type === 'select' || item.type === 'multiSelect') {
            customFormFields.push({
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "options",
                    label: `Options (comma separated)`,
                    // readonly: true
                }
            );

            customFormFields.push({
                kind: CustomFormFieldTypes.PASSTHRU,
                size: CustomFormFieldSizes.FULL,
                key: "quickOptions",
                label: "Quick Options",
                passthru: QuickSelectOptions,
                passthruProps: { item: item, setItem: setItem, surveyObject: question }
            })
        }

        if (item.type === 'toggle') {
            customFormFields.push({
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: "defaultValue",
                    label: `Default Value`,
                    options: [
                        {label: "TRUE", value: true},
                        {label: "FALSE", value: false},
                    ]
                }
            );
        }

        return customFormFields;
    }, [ item, question ])


    return (<>
        <IconButton color={"secondary"} onClick={() => {
            setOpen(true);
        }}><EditIcon /></IconButton>
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            {open ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Edit Question From Bank
                    </Typography>

                    <br />

                    <EditObject
                        item={item}
                        setItem={setItem}
                        form={formFieldsMemo}
                        columns={12}
                    />

                    <br />

                    <pre>{JSON.stringify(question, undefined, 4)}</pre>

                    <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={async () => {
                        let options = undefined;
                        if (item.options !== undefined && Array.isArray(item.options)) {
                            options = item.options
                        }
                        else if (item.options !== undefined && typeof item.options === "string") {
                            options = item.options.split(",").map((option: string) => option.trim());
                        }

                        let questionToUpdate = {
                            ...question, content: {
                                ...item,
                                type: {
                                    type: item.type,
                                    options: options,
                                    defaultValue: item.defaultValue ?? undefined
                                },
                            }
                        };
                        if (await saveQuestion(questionToUpdate)) {
                            setOpen(false);
                        }
                    }}>SAVE</Button>
                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};



export default QuickQuestionView;
